.product-details {
  display: flex;
  flex-direction: column;
  .header {
    .icon {
      font-size: 35px;
      color: var(--color-blue-dark);
      cursor: pointer;
    }
  }
  .details {
    display: flex;
    flex-direction: column;
    .heading {
      align-self: center;
    }
    .details-card {
      display: flex;
      flex-direction: column;
      padding: 10px;
      min-height: 250px;
      background-color: var(--color-pink-light);
      .heading {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .content-x {
        display: flex;
        align-items: center;
        column-gap: 10px;
        justify-content: flex-start;
      }
    }
  }
}
