

.coupon-data .table-data{
    border: 1px solid rgb(236, 228, 228);
    width: 100%;
    margin: auto;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0 15px;
}
th{
    background-color: #00000005;
    padding: 16px;
}
.table-data .trr
      td {
        text-align: center;
        padding: 10px;

      }


      