.grey {
  background-color: grey;
}
.blue {
  background-color: blue;
}
.ant-switch-checked {
  background: blue !important;
}
.ant-switch-inner{
  background-color: pink;
}