.login {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // background-image: url("bible.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  .login-form {
    display: flex;
    min-width: 40%;
    // width: fit-content;
    height: fit-content;
    // background-color: var(--color-pink-light);
     border: 10px solid rgb(250, 239, 239);
    background-color: var(--color-medium-pink);
    opacity: 0.8;
    border-radius: 40px;
    opacity: 0.8;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    // h1 {
    // }
       img {
      width: 300px;
      height: 100px;
      object-fit: contain;
    }
    h2 {
      text-align: center;
    }
    .fields {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      position: relative;
      .field-email {
        width: 350px;
        border: none;
        outline: none;
        padding: 6px;
        padding-left: 30px;
        font-size: 16px;
        font-weight: 500;
        font-family: Arial, Helvetica, sans-serif;
      }
      .password-field {
        position: relative;
        display: flex;
        flex-direction: column;
        .field-password {
          width: 350px;
          border: none;
          outline: none;
          padding: 6px;
          padding-left: 30px;
          font-size: 16px;
          font-weight: 500;
          margin-top: 20px;
          font-family: Arial, Helvetica, sans-serif;
        }
        .icon-lock {
          position: absolute;
          top: 30px;
          left: 5px;
          font-size: 20px;
        }
        .icon-eye {
          position: absolute;
          font-size: 23px;
          right: 5px;
          top: 28px;
          cursor: pointer;
        }
      }
      .icon-user {
        position: absolute;
        top: 6px;
        left: 5px;
        font-size: 20px;
      }
    }
    .forgot {
      text-align: right;
      margin: 5px;
      font-size: 16px;
      color: black;
      cursor: pointer;
      &:hover {
        color: blue;
      }
    }
    .btn {
      padding: 5px;
      text-align: center;
      button {
        padding: 5px;
        width: 150px;
        margin: 10px;
        font-weight: bold;
        cursor: pointer;
        border: none;
        color: white;
        background-color: var(--color-blue-dark);
        transition: 0.5s all ease-in-out;
        border-radius: 5px;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
#error {
  color: rgb(185, 17, 17);
  font-size: 16px;
}
@media screen and (max-width: 600px) {
  .login {
    .login-form {
      width: 95%;
      height: 65%;
      // .fields {
      //   .field-email {
      //   }
      //   .password-field {
      //   }
      // }
    }
  }
}
