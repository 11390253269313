* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.layout {
  width: 100%;
  height: 100vh;
  .sidebar {
    width: 16%;
    background-color: var(--color-pink-light);
    height: 100%;
    position: fixed;
    border-right: 1px solid rgb(250, 250, 248);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    .logo {
      background-image: url("../assets/logo.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin: 10px;
      width: 150px;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
    }
    .sidebar-menu {
      position: absolute;
      top: 100px;
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 10px;
      width: 100%;
      .nav-links {
        display: flex;
        align-items: center;
        column-gap: 5px;
        padding: 5px;
        background-color: var(--color-white);
        border-radius: 5px;
        width: 95%;
        text-decoration: none;
        .icon {
          font-size: 22px;
          color: var(--primary-color);
        }
        p {
          margin: 0px;
          font-size: 18px;
          color: var(--color-blue-dark);
        }
        &.active {
          background-color: var(--color-blue-dark);
          p {
            color: var(--color-white);
          }
          .icon {
            color: var(--color-white);
          }
        }
        &:hover {
          background-color: var(--color-blue-dark);
          p {
            color: var(--color-white);
          }
          .icon {
            color: var(--color-white);
          }
        }
      }
    }
  }
  .topbar {
    width: 100%;
    height: 60px;
    background-color: var(--color-pink-light);
    position: fixed;
    z-index: 999;
    .profile {
      position: absolute;
      right: 20px;
      top: 10px;
      height: 100%;
      .profile-desc {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 5px;
        img {
          width: 39px;
          height: 39px;
        }
        p {
          margin: 0px;
          font-size: 16px;
          color: var(--color-blue-dark);
        }
        .icon {
          font-size: 25px;
          cursor: pointer;
          color: var(--color-blue-dark);
          margin-left: 20px;
          transition: all 0.5s ease-in;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    .menu {
      position: absolute;
      right: 5px;
      top: 110%;
      width: 200px;
      height: 200px;
      background-color: rgb(244, 206, 102);
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .content {
    padding-top: 60px;
    padding-left: 16%;
    .app-container {
      padding: 10px 20px;
      overflow: auto;
    }
  }
}
@media screen and (max-width: 1130px) {
  .sidebar {
    .logo {
      background-repeat: no-repeat !important;
      background-size: contain !important;
      width: 100px !important;
      height: 100px !important;
      margin: 10px !important;
    }
  }
}
@media screen and (max-width: 1000px) {
  .sidebar {
    .logo {
      background-repeat: no-repeat !important;
      background-size: contain !important;
      width: 80px !important;
      height: 80px !important;
      margin: 10px !important;
    }
  }
}
@media screen and (max-width: 600px) {
  .sidebar {
    .logo {
      background-repeat: no-repeat !important;
      background-size: contain !important;
      width: 40px !important;
      height: 40px !important;
      margin: 10px !important;
    }
    .sidebar-menu {
      .nav-links {
        justify-content: center;
        width: 70% !important;
        p {
          display: none;
        }
      }
    }
  }
  .topbar {
  }
  .content {
    .app-container {
      padding: 5px !important;
    }
  }
}
