.influencerRequests {
  .search-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .search {
      position: relative;
      .search-input {
        padding: 5px;
        padding-left: 25px;
        width: 200px;
        outline: none;
        border-radius: 5px;
        border: none;
        background-color: var(--color-pink-light);
        color: var(--secondary-dark);
      }
      .icon {
        position: absolute;
        left: 0;
        top: 6px;
        font-size: 20px;
        color: var(--secondary-dark);
      }
      button {
        padding: 5px;
        border: none;
        margin-left: 5px;

        border-radius: 5px;
        color: white;
        cursor: pointer;
      }
    }
  }
  .header-row {
    background-color: var(--light-blue);
    padding: 15px 5px;
    border-radius: 0px;
    margin-bottom: 10px;
    p {
      padding: 0 !important;
      margin: 0 0 !important;
    }
  }
  .row-e {
    background-color: var(--primary-light);
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 5px;
  }
  .e-cell {
    display: flex;
    .cell-title {
      flex: 1;
      display: none;
    }
    .cell-content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;
      .icon {
        font-size: 20px;
        cursor: pointer;
      }
      .del {
        color: rgb(175, 13, 13);
        font-size: 23px;
      }

      .switch-e {
        width: 120px;
        display: flex;
        justify-content: space-between;
        a {
          font-size: 16px;
          color: var(--link);
        }
      }
      .view-order {
        font-size: 18px;
        cursor: pointer;
        color: var(--color-blue-dark);
      }
    }
  }
}
@media screen and (max-width: 950px) {
  .influencerRequests {
    .header-row {
      display: none;
    }
    .e-cell {
      .cell-title {
        display: block;
        p {
          margin: 0px !important;
        }
      }
    }
  }
}
@media screen and (max-width: 699px) {
  .influencerRequests {
    .search-row {
      flex-direction: column;
      margin-bottom: 10px;
    }
  }
}
.ant-switch-checked {
  background-color: var(--secondary-dark) !important;
}
